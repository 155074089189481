import * as types from 'app-redux/types/actionTypes';
import { Pair } from 'types/objectTypes';

export const setPages = <T>(pair: Pair<T>) => ({
  type: types.SET_PAGES,
  payload: pair,
});

export const setSlug = (slug: string) => ({
  type: types.SET_SLUG,
  payload: slug,
});

export const setReferrer = (referrer: string) => ({
  type: types.SET_REFERRER,
  payload: referrer,
});
