import cookie from 'js-cookie';
import { serialize } from 'cookie';

import { Logger } from 'lib/logger';

export class CookiesBuilder<T> {
  constructor(private defaultOptions: T) {
    this.defaultOptions = defaultOptions;
  }

  public set(name: string, value?: string | object, options?: T) {
    if (!value) {
      return this;
    }

    if (typeof value === 'object') {
      try {
        const serializedCookie = serialize(name, JSON.stringify(value), options as any);
        document.cookie = serializedCookie;
      } catch (e) {
        Logger.error(e);
      }
    } else {
      cookie.set(name, value, { ...this.defaultOptions, ...options });
    }

    return this;
  }

  public remove(name: string, options?: T) {
    cookie.remove(name, { ...this.defaultOptions, ...(options || {}) });
  }
}

export const calculateCookiesDomain = (origin?: string, domainValue?: string) => {
  const isCurrentOriginEqualsToCPName = process.env.NODE_ENV === 'production'
    && origin?.includes(domainValue || process.env.CP_DOMAIN || '');

  if (isCurrentOriginEqualsToCPName) {
    return domainValue || process.env.CP_DOMAIN;
  }
};
