import cookie from 'cookie';

import { SESSION_COOKIE_NAME } from 'constants/constants';
import { sessionCookieOptions } from 'lib/session.service';

export const getAuthProviderSessionCookie = () => {
  if (window.location.origin.includes(process.env.NEXT_PUBLIC_CP_DOMAIN || '')
    && process.env.NODE_ENV === 'production') {
    return { ...sessionCookieOptions, domain: process.env.NEXT_PUBLIC_CP_DOMAIN };
  }

  return sessionCookieOptions;
};

export const invalidateSessionRedirect = () => {
  const currentPath = document.location.href;
  document.cookie = cookie
    .serialize(SESSION_COOKIE_NAME, '', { ...getAuthProviderSessionCookie(), expires: new Date() });
  document.cookie = cookie
    .serialize('redirect', currentPath, { secure: true });
  window.location.replace(`${document.location.origin}/sign-in`);
};
