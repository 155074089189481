import { capitalizeFirstLetter } from 'lib/text.service';
import { Pair } from 'types/objectTypes';

const LAST_MONTH_INDEX = 11;

/**
 * {@link https://docs.oracle.com/cd/E41183_01/DR/Date_Format_Types.html Oracle docs}
 *  */
export const getOracleFormattedDate = (
  date: Date | string,
  format: 1 | 2 | 3 | 4 | 5 | 6,
  locale: string = 'default',
  divider: string = '/',
) => {
  const handledDate = new Date(date);

  switch (format) {
    case 1: {
      return `${handledDate.getMonth()}${divider}${handledDate.getDate()}${divider}${handledDate.getFullYear()}`;
    }
    case 2: {
      return `${handledDate.getDate()}${divider}${handledDate.getMonth()}${divider}${handledDate.getFullYear()}`;
    }
    case 3: {
      return `${handledDate.getFullYear()}${divider}${handledDate.getMonth()}${divider}${handledDate.getDate()}`;
    }
    case 4: {
      const month = capitalizeFirstLetter(handledDate.toLocaleString(locale, { month: 'long' }));
      const year = handledDate.getFullYear();
      const day = handledDate.getDate();

      return `${month} ${day}, ${year}`;
    }
    case 5: {
      const month = handledDate.toLocaleString(locale, { month: 'numeric' });
      const year = handledDate.getFullYear();
      const day = handledDate.toLocaleString(locale, { day: 'numeric' });

      return `${month}${divider}${day}${divider}${year}`;
    }
    case 6: {
      const month = handledDate.toLocaleString(locale, { month: 'numeric' });
      const year = handledDate.getFullYear();
      const day = handledDate.toLocaleString(locale, { day: 'numeric' });

      return `${day}${divider}${month}${divider}${year}`;
    }
    default: {
      return handledDate.toDateString();
    }
  }
};

export const getDaysAmountInMonth = (monthIndex?: number, yearIndex?: number) => {
  const date = new Date();
  const month = monthIndex || date.getMonth() + 1;
  const year = yearIndex || date.getFullYear();
  const daysAmount = new Date(year, month, 0).getDate();

  return daysAmount;
};

export const getLocalizedFullMonthDYDate = (locale?: string, date: Date = new Date()) => {
  const currentLocale = locale || 'default';
  const month = capitalizeFirstLetter(date.toLocaleString(currentLocale, { month: 'long' }));
  const daysAmount = getDaysAmountInMonth(date.getMonth() + 1, date.getFullYear());
  const currentDate = date.getDate();

  if (daysAmount < currentDate) {
    const newDay = currentDate - daysAmount;

    if (date.getMonth() === LAST_MONTH_INDEX) {
      const newYear = date.getFullYear() + 1;
      date.setFullYear(newYear);
      date.setMonth(0, newDay);
    } else {
      const newMonthIndex = date.getMonth() + 1;
      date.setMonth(newMonthIndex, newDay);
    }

    const month = capitalizeFirstLetter(date.toLocaleString(currentLocale, { month: 'long' }));
    const year = date.getFullYear();
    const day = date.getDate();

    return `${month} ${day}, ${year}`;
  }

  const year = date.getFullYear();

  return `${month} ${currentDate}, ${year}`;
};

export const formatDateToDateInput = (
  date: Date = new Date(),
  datePeriodForSubtract?: { year?: number, month?: number, day?: number },
) => {
  const year = new Intl.DateTimeFormat(undefined, { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat(undefined, { month: 'numeric' }).format(date);
  const day = new Intl.DateTimeFormat(undefined, { day: '2-digit' }).format(date);

  try {
    if (datePeriodForSubtract) {
      const newYear = datePeriodForSubtract.year
        ? +year - datePeriodForSubtract.year
        : year;
      const newMonth = datePeriodForSubtract.month
        ? +month - datePeriodForSubtract.month
        : month;
      const newDay = datePeriodForSubtract.day
        ? +day - datePeriodForSubtract.day
        : day;

      return `${newYear}-${newMonth}-${newDay}`;
    }

    return `${year}-${month}-${day}`;
  } catch {
    return `${year}-${month}-${day}`;
  }
};

export const getMonths = (
  format: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
  locale?: string,
  // @ts-ignore
) => [...new Array(12).keys()]
  .map((key) => new Date(0, key).toLocaleString(locale, { month: format }));

export const getMonthsWithIndexes = (
  format: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
  locale?: string,
  // @ts-ignore
): Array<Pair<string>> => [...new Array(12).keys()]
  .map((key) => ({
    key: key + 1,
    value: new Date(0, key).toLocaleString(locale, { month: format }),
  }));

// @ts-ignore
export const getDefaultDaysInMonthArray = (days: number = 31) => [...new Array(days).keys()]
  .map((key) => key + 1);

export const getYears = (yearsAmount: number) => {
  const currentYear = new Date().getFullYear();

  // @ts-ignore
  return [...new Array(yearsAmount).keys()]
    .map((key) => currentYear - key);
};

export const calculateAge = (birthDate: Date) => {
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  const months = today.getMonth() - birthDate.getMonth();

  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }

  return age;
};

export const getDateWithNewTimezone = (
  timeZone: string,
  date: string | Date = new Date(),
) => new Date((typeof date === 'string' ? new Date(date) : date)
  .toLocaleString('default', { timeZone }));
