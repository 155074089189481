import {
  RemoteSearchOptionsType,
  RequestPsychicParams,
  RequestPsychicParamsProxy,
  SearchOptionsType,
  UserType,
} from 'types/objectTypes';

export const mapPsychicProxyRequestParams = (
  proxyRequestParameters: RequestPsychicParamsProxy,
): RequestPsychicParams => ({
  CustId: proxyRequestParameters.custId,
  ExtId: proxyRequestParameters.extId,
  CookieName: proxyRequestParameters.cookieName,
  PageSize: proxyRequestParameters.pageSize,
  PageIndex: proxyRequestParameters.pageIndex,
  CustFavorite: proxyRequestParameters.custFavorite,
  SearchText: proxyRequestParameters.searchText,
  SortBy: proxyRequestParameters.sortBy,
  SearchOptions: proxyRequestParameters.searchOptions,
  CurrentView: proxyRequestParameters.currentView,
  ResultType: proxyRequestParameters.resultType,
  AppId: proxyRequestParameters.appId,
  TestimonialCount: proxyRequestParameters.testimonialCount,
  ...proxyRequestParameters,
});

export const mapPsychicFromRemoteServer = (psychic: any, session: UserType | null) => {
  if (!psychic) {
    return {};
  }

  if (Array.isArray(psychic.specialities)) {
    return psychic;
  }

  const newPsychic = psychic;
  const allSpecialities: Array<string> = psychic.specialities?.split(',') || [];
  newPsychic.specialities = (allSpecialities.length <= 3)
    ? allSpecialities
    : allSpecialities.slice(0, 3);
  newPsychic.tools = psychic.tools?.split(',') || [];
  const onWordName = (psychic.lineName as string)?.replace(/ /g, '') || '';
  newPsychic.psychicBioURL = `/${onWordName.toLowerCase()}-${psychic.extId}`;
  newPsychic.psychicImageUrl = psychic.images
    ? (psychic.images[3] || '')
    : '';
  newPsychic.basePrice = `$${psychic.basePrice?.toFixed(2)}`;
  newPsychic.customerPrice = (session && Object.keys(session).length)
    ? `$${psychic.customerPrice?.toFixed(2)}`
    : `$${psychic.newCustFlatRate?.toFixed(2)}`;
  newPsychic.psychicName = psychic.lineName || '';
  newPsychic.phoneStatus = psychic.lineStatus || '';
  const testimonials = psychic.featuredTestimonialData || psychic.lstTestimonial?.[0];

  if (testimonials) {
    newPsychic.featuredTestimonialData = psychic.featuredTestimonialData
      || psychic.lstTestimonial?.[0];
  }

  return psychic;
};

export const mapSearchOptions = (options: SearchOptionsType): RemoteSearchOptionsType => ({
  Subject: options.subject || '',
  Ability: options.ability || '',
  Tool: options.tool || '',
  Style: options.style || '',
  Price: options.price || '',
  IsNewPsychics: options.isNewPsychics || '',
});
